import Vue from "@/helper/initVue";
import store from "@/store";

import TariffCalculator from "@/features/tariff-calculator";
import {
  TariffCalculatorConfig,
  TariffCalculatorDataConfig,
} from "@/features/tariff-calculator/components/TariffCalculator";

const elements = document.querySelectorAll(".tariff-calculator");

Array.from(elements).forEach((el) => {
  let calculatorConfig: TariffCalculatorConfig = {};

  try {
    const dataConfig = el.getAttribute("data-config");
    const dataShowProducts = el.getAttribute("data-show-products");

    if (dataConfig) {
      const urlParams = new URLSearchParams(window.location.search);

      const parsedConfig = JSON.parse(dataConfig) as TariffCalculatorDataConfig;
      const showProducts = dataShowProducts
        ? !!JSON.parse(dataShowProducts)
        : false;
      let availableTabs: number[] = [];
      let resultURL = "";
      let resultURLService = "";
      let debugMode = false;
      let customerType = "";
      let calculatorMini = false;
      let contractUrls: Record<string, string> = {};
      let serviceNo: Record<string, string> = {};
      let promotionCode = "";
      let imageUrl = "";
      let allowedProductIds = "";

      if (Array.isArray(parsedConfig)) {
        availableTabs = parsedConfig.reduce((availableTabs: number[], item) => {
          if (item.id) {
            availableTabs = [...availableTabs, item.id];
          } else if (typeof item === "string") {
            resultURL = item;
          } else if (typeof item === "boolean") {
            debugMode = item;
          }

          return availableTabs;
        }, []);
      } else {
        const {
          result_url,
          result_url_service,
          customer_type,
          debug,
          calculator_mini,
          image_url,
          allowed_product_ids,
          contract_urls,
          service_no,
          promo_code,
          ...rest
        } = parsedConfig;

        const keys = Object.keys(rest);

        availableTabs = keys.reduce(
          (availableTabs: number[], id) => [
            ...availableTabs,
            parsedConfig[id].id,
          ],
          []
        );

        resultURL = (result_url as unknown) as string;
        resultURLService = (result_url_service as unknown) as string;
        customerType = (customer_type as unknown) as string;
        debugMode = (debug ? debug : (debugMode as unknown)) as boolean;
        calculatorMini = (calculator_mini as unknown) as boolean;
        imageUrl = (image_url as unknown) as string;
        allowedProductIds = (allowed_product_ids as unknown) as string;
        contractUrls = (contract_urls as unknown) as Record<string, string>;
        serviceNo = (service_no as unknown) as Record<string, string>;
        promotionCode =
          ((promo_code as unknown) as string) ||
          urlParams.get("promotionCode") ||
          "";
      }

      calculatorConfig = {
        debugMode,
        resultURL,
        resultURLService,
        customerType,
        showProducts,
        availableTabs,
        calculatorMini,
        imageUrl,
        allowedProductIds,
        contractUrls,
        serviceNo,
        promotionCode,
      };
    }
  } catch (err) {
    // Nothing to do here
  }

  new Vue({
    el,
    store: store.original, // Inject the classic Vuex store
    render: (h) =>
      h(TariffCalculator, {
        props: {
          config: calculatorConfig,
        },
      }),
  });
});
